<template>
    <div class="animated fadeIn">
        <CRow>
            <CCol lg="6">
                <CCol lg="4" style="padding: 0%;" class="total-activity-container">
                    <CWidgetIcon :header="totalActivity.toString()" text="Total Activity" color="info"
                        style="margin: 2%;">
                        <CIcon name="cil-task" />
                        <template #footer>
                            <CLink v-if="!card_loading" class="font-weight-bold font-xs text-body-secondary text-center underlined"
                                v-c-popover="{
                                    header: 'Total Activity Summary',
                                    content:
                                        'Draft: <strong>' + status_list[0].draft.toString() + ' (<span style=\'color: grey;\'>' + totalStatusPercentage[0].toFixed(2) + '%</span>)</strong>'
                                        + '<br> Signing: <strong>' + status_list[0].signing.toString() + ' (<span style=\'color: blue;\'>' + totalStatusPercentage[1].toFixed(2) + '%</span>)</strong>'
                                        + '<br> Completed: <strong>' + status_list[0].completed.toString() + ' (<span style=\'color: green;\'>' + totalStatusPercentage[2].toFixed(2) + '%</span>)</strong>'
                                        + '<br> Cancelled: <strong>' + status_list[0].cancelled.toString() + ' (<span style=\'color: red;\'>' + totalStatusPercentage[3].toFixed(2) + '%</span>)</strong>',
                                    placement: 'right-end'
                                }">
                                View Details
                            </CLink>
                        </template>
                        <CElementCover :opacity="0.8" v-if="card_loading" />
                    </CWidgetIcon>
                </CCol>
            </CCol>
            <CCol lg="6" class="filter-container">
                <CButton color="primary" @click="openAdvanceFilter()"
                    :disabled="!category_loading && !user_loading && !templates_loading && !filter_loading ? false : true">
                    <font-awesome-icon
                        :icon="!category_loading && !user_loading && !templates_loading && !filter_loading ? 'sliders-h' : 'circle-notch'"
                        :pulse="!category_loading && !user_loading && !templates_loading && !filter_loading ? false : true" />
                    Advance Filter
                    <CBadge color="info" shape="pill" v-if="numberOfFilter > 0">{{ numberOfFilter }}</CBadge>
                </CButton>
                <CButton color="warning" @click="removeFilter()">
                    <font-awesome-icon :icon="remove_filter ? 'circle-notch' : 'eraser'"
                        :pulse="remove_filter ? true : false" />
                    Remove Filter
                </CButton>
                <CDropdown color="success" toggler-text="Export Excel" :disabled="export_btn_loading ? true : false">
                    <template #toggler>
                        <button class="btn btn-success dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <font-awesome-icon :icon="export_btn_loading ? 'circle-notch' : 'file-excel'"
                                :pulse="export_btn_loading ? true : false" />
                            Export Excel
                        </button>
                    </template>

                    <CDropdownItem @click="exportSummary()"
                        v-c-tooltip="{ content: 'It will export the total of activities to all statuses', placement: 'top' }">
                        Summary
                    </CDropdownItem>

                    <CDropdownDivider></CDropdownDivider>
                    <CDropdownItem
                        @click="numberOfFilter == 0 ? noFilterConfirmation('1,2,3,4') : exportByStatus('1,2,3,4')">
                        All Status
                    </CDropdownItem>
                    <CDropdownItem @click="numberOfFilter == 0 ? noFilterConfirmation(1) : exportByStatus(1)">Draft
                    </CDropdownItem>
                    <CDropdownItem @click="numberOfFilter == 0 ? noFilterConfirmation(2) : exportByStatus(2)">
                        Signing</CDropdownItem>
                    <CDropdownItem @click="numberOfFilter == 0 ? noFilterConfirmation(3) : exportByStatus(3)">
                        Completed</CDropdownItem>
                    <CDropdownItem @click="numberOfFilter == 0 ? noFilterConfirmation(4) : exportByStatus(4)">
                        Cancelled</CDropdownItem>
                </CDropdown>
            </CCol>
        </CRow>
        <br>
        <CRow>
            <CCol sm="3">
                <CWidgetProgressIcon :header="status_list[0].draft.toString()" text="Draft">
                    <template #progress>
                        <CProgress animated color="dark" :value="progressBar[0]" v-show="isFiltered"
                            v-c-tooltip="{ content: status_list[0].draft + ' out of ' + status_init_value[0].draft + ' entire draft document', placement: 'top' }" />
                        <CProgress animated color="dark" :value="progressBar[0]" v-show="!isFiltered" />
                        <div class="d-flex justify-content-center align-items-center" v-if="!isFiltered && !card_loading">
                            <CLink 
                            class="font-weight-bold font-xs text-body-secondary underlined" 
                            style="padding-top: 10px;"
                            @click="() => { selected_status = 'Draft';}"
                            >
                                View Draft
                            </CLink>
                        </div>
                    </template>
                    <CIcon name="cil-notes" height="36" />
                    <p class="percentage" v-show="isFiltered"
                        v-c-tooltip="{ content: progressBar[0].toFixed(2) + '% of the entire draft document', placement: 'top' }">
                        {{ progressBar[0].toFixed(2) + "%" }}
                    </p>
                    <CElementCover :opacity="0.8" v-if="card_loading" />
                </CWidgetProgressIcon>
            </CCol>
            <CCol sm="3">
                <CWidgetProgressIcon :header="status_list[0].signing.toString()" text="Signing" color="info">
                    <template #progress>
                        <CProgress animated color="primary"  v-show="isFiltered" :value="progressBar[1]"
                            v-c-tooltip="{ content: status_list[0].signing + ' out of ' + status_init_value[0].signing + ' entire signing document', placement: 'top' }" />
                        <CProgress animated color="primary" :value="progressBar[1]"  v-show="!isFiltered" />
                        <div class="d-flex justify-content-center align-items-center" v-if="!isFiltered && !card_loading">
                            <CLink 
                            class="font-weight-bold font-xs text-body-secondary underlined" 
                            style="padding-top: 10px;"
                            @click="() => { selected_status = 'Signing';}"
                            >
                                View Signing
                            </CLink>
                        </div>
                    </template>
                    <CIcon name="cil-PenAlt" height="36" />
                    <p class="percentage" v-show="isFiltered"
                        v-c-tooltip="{ content: progressBar[1].toFixed(2) + '% of the entire signing document', placement: 'top' }">
                        {{ progressBar[1].toFixed(2) + "%" }}
                    </p>
                    <CElementCover :opacity="0.8" v-if="card_loading" />
                </CWidgetProgressIcon>
            </CCol>
            <CCol sm="3">
                <CWidgetProgressIcon :header="status_list[0].completed.toString()" text="Completed">
                    <template #progress>
                        <CProgress animated color="success" :value="progressBar[2]"  v-show="isFiltered"
                            v-c-tooltip="{ content: status_list[0].completed + ' out of ' + status_init_value[0].completed + ' entire completed document', placement: 'top' }" />
                        <CProgress animated color="success" :value="progressBar[2]"  v-show="!isFiltered" />
                        <div class="d-flex justify-content-center align-items-center" v-if="!isFiltered && !card_loading">
                            <CLink 
                            class="font-weight-bold font-xs text-body-secondary underlined" 
                            style="padding-top: 10px;"
                            @click="() => { selected_status = 'Completed';}"
                            >
                                View Completed
                            </CLink>
                        </div>
                    </template>
                    <CIcon name="cil-check" height="36" />
                    <p class="percentage" v-show="isFiltered"
                        v-c-tooltip="{ content: progressBar[2].toFixed(2) + '% of the entire completed document', placement: 'top' }">
                        {{ progressBar[2].toFixed(2) + "%" }}
                    </p>
                    <CElementCover :opacity="0.8" v-if="card_loading" />
                </CWidgetProgressIcon>
            </CCol>
            <CCol sm="3">
                <CWidgetProgressIcon :header="status_list[0].cancelled.toString()" text="Cancelled" color="info">
                    <template #progress>
                        <CProgress animated color="danger" :value="progressBar[3]" v-show="isFiltered"
                            v-c-tooltip="{ content: status_list[0].cancelled + ' out of ' + status_init_value[0].cancelled + ' entire cancelled document', placement: 'top' }" />
                        <CProgress animated color="danger" :value="progressBar[3]" v-show="!isFiltered" />
                        <div class="d-flex justify-content-center align-items-center" v-if="!isFiltered && !card_loading">
                            <CLink 
                            class="font-weight-bold font-xs text-body-secondary underlined" 
                            style="padding-top: 10px;"
                            @click="() => { selected_status = 'Cancelled';}"
                            >
                                View Cancelled
                            </CLink>
                        </div>
                    </template>
                    <CIcon name="cil-XCircle" height="36" />
                    <p class="percentage" v-show="isFiltered"
                        v-c-tooltip="{ content: progressBar[3].toFixed(2) + '% of the entire cancelled document', placement: 'top' }">
                        {{ progressBar[3].toFixed(2) + "%" }}
                    </p>
                    <CElementCover :opacity="0.8" v-if="card_loading" />
                </CWidgetProgressIcon>
            </CCol>
        </CRow>
        <CRow v-if="!isFiltered && !card_loading">
            <CCol class="text-center">
                <CButton color="info" @click="viewAll()" variant="outline">
                    <font-awesome-icon icon="eye" />
                    View all Activity
                </CButton>
            </CCol>
        </CRow>
        <CRow v-if="isFiltered">
            <CCol col="2">
                <label>
                    Status
                </label>
                <v-select label="Selected Category" :options="Object.keys(statuses)" :clearable="false"
                    v-model="selected_status" />
                <br>
            </CCol>
        </CRow>

        <CCard v-if="isFiltered">
            <CCardHeader><font-awesome-icon icon="list-check" /> Routing Activity</CCardHeader>
            <CCardBody>
                <CDataTable :items="activity_report_list" :fields="activity_report_list_fields"
                    :table-filter="{ placeholder: 'Your text here.', label: 'Search:' }" :items-per-page="10" border
                    items-per-page-select sorter pagination hover :loading="this.table_loading">
                    <template slot="no-items-view" v-if="this.table_loading">
                        <center>
                            <CSpinner style="width:4rem;height:4rem;" color="info" />
                        </center>
                    </template>
                    <template #reference_number="{ item }">
                        <td class="p-1" :title="item.reference_number">
                            {{ item.reference_number.length > 25 ? item.reference_number.substring(0, 25) + '...' :
                                item.reference_number }}
                        </td>
                    </template>

                    <template #description="{ item }">
                        <td class="p-1" v-if="item.description" :title="item.description">
                            {{ item.description.length > 15 ? item.description.substring(0, 15) + '...' :
                                item.description }}
                        </td>
                        <td class="p-1" v-else>
                        </td>
                    </template>

                    <template #name="{ item }">
                        <td class="p-1" :title="item.name">
                            {{ item.name.length > 15 ? item.name.substring(0, 15) + '...' : item.name }}
                        </td>
                    </template>

                    <template #date="{ item }">
                        <td class="p-1 text-center" :title="changeDateFormat(item.date)">
                            <small>{{ changeDateFormatV2(item.date) }}</small>
                        </td>
                    </template>

                    <template #completed_date="{ item }">
                        <td class="p-1 text-center" :title="changeDateFormat(item.completed_date)">
                            <small v-show="item.status === 'Completed'">
                            {{  changeDateFormatV2(item.completed_date) }}
                            </small>
                        </td>
                    </template>

                    <template #status="{ item }">
                        <td class="p-1">
                            <CBadge :color="getColorStatus(item.status.toLowerCase())">
                                {{ item.status }}
                            </CBadge>
                        </td>
                    </template>

                </CDataTable>
            </CCardBody>
        </CCard>

        <CModal :show.sync="custom_filter" color="primary" :closeOnBackdrop="false">

            <template #header>
                <h5> <font-awesome-icon icon="sliders-h" /> Advance Filter </h5>
                <CButton class="pull-right" color="light" shape="pill" size="sm" @click="custom_filter = false;">
                    <font-awesome-icon icon="times" />
                </CButton>
            </template>

            <template #body-wrapper>
                <CCardBody>
                    <CRow>
                        <CCol lg="12">
                            <label> Document Creator </label>
                            <v-select :disbled="list_of_creator.length < 0" :options="list_of_creator"
                                label="first_name" v-model="selected_creator"
                                :reduce="list_of_creator => list_of_creator.id">
                                <template #list-header>
                                    <li style="text-align: center">Search by first name</li>
                                </template>

                                <template v-slot:option="d_name">
                                    {{ d_name.first_name }} {{ d_name.last_name }} {{ d_name.suffix }}
                                </template>

                                <template v-slot:selected-option="s_name">
                                    {{ s_name.first_name }} {{ s_name.last_name }}{{ s_name.suffix }}
                                </template>
                            </v-select>
                        </CCol>
                    </CRow>

                    <CRow>
                        <CCol lg="12">
                            <label> For Signing By </label>
                            <v-select :disbled="users.length < 0" :options="users" label="first_name"
                                v-model="selected_template_signatory">
                                <template #list-header>
                                    <li style="text-align: center">Search by first name</li>
                                </template>

                                <template v-slot:option="d_name">
                                    {{ d_name.first_name }} {{ d_name.last_name }} {{ d_name.suffix }}
                                </template>

                                <template v-slot:selected-option="s_name">
                                    {{ s_name.first_name }} {{ s_name.last_name }}{{ s_name.suffix }}
                                </template>
                            </v-select>
                        </CCol>
                    </CRow>

                    <CRow>
                        <CCol lg="12">
                            <label> Document Category </label>
                            <v-select :disbled="templates.length < 0" :options="templates" label="category"
                                v-model="selected_category">
                            </v-select>
                        </CCol>
                    </CRow>

                    <CRow>
                        <CCol lg="12">
                            <v-select class="v-slct" :options="list_of_templates" label="name"
                                v-model="selected_user_templates" :reduce="list_of_templates => list_of_templates.id">
                                <template #header>
                                    <div style="margin-bottom: 9px;">Document Templates</div>
                                </template>
                            </v-select>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg="12">
                            <label>
                                Date Created
                            </label>
                            <CDateRange ref="_CDateRange" />
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg="12">
                            <CButton class="float-right" color="primary" @click="filterActivityReport();"
                                :disabled="advance_filter ? true : false">
                                <font-awesome-icon :icon="advance_filter ? 'circle-notch' : 'share-square'"
                                    :pulse="advance_filter ? true : false" />
                                Search
                            </CButton>
                        </CCol>
                    </CRow>
                </CCardBody>
            </template>

            <template #footer>
                <hr hide>
            </template>
        </CModal>
        <UsersModal ref="usersModal" @selectedUser="userSelected($event)" />
    </div>
</template>

<style scoped>
.filter-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.filter-container button {
    margin-left: 10px;
}

.total-activity-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.percentage {
    float: left;
    font-size: medium;
}

.underlined{
    text-decoration: underline;
}
</style>

<script>
import UsersModal from '../../modals/UsersModal'
import CDateRange from '../../components/CDateRange'


export default {
    name: 'DocumentRoutingHome',
    components: {
        UsersModal,
        CDateRange,
    },
    data() {
        return {
            activity_report_list: [],
            status_list: [{
                draft: 0,
                signing: 0,
                completed: 0,
                cancelled: 0,
            }],
            templates: [],
            users: [],
            list_of_creator: [],
            card_loading: true,
            template_signatory: {
                user_id: null,
                name: null,
                purpose: null,
                order: null,
                required_signature: true,
            },
            user_templates_lists: [],
            selected_user_templates: null,
            custom_filter: false,
            selected_category: null,
            selected_creator: null,
            selected_template_signatory: null,
            status_init_value: [0,0,0,0],
            progressBar: [100.0, 100.0, 100.0, 100.0],
            percentage: 0.0,
            isFiltered: false,
            remove_filter: false,
            export_btn_loading: false,
            table_loading: false,
            statuses: {
                All: null,
                Draft: 1,
                Signing: 2,
                Completed: 3,
                Cancelled: 4
            },
            selected_status: '',
            status_filter: false,
            advance_filter: false,
            list_of_templates: [],
            templates_loading: true,
            category_loading: true,
            user_loading: true,
            view_all_loading: false,
            numberOfFilter: 0,
            isAdvanceFilterActive: false,
            totalActivity: 0,
            totalStatusPercentage: [100.0, 100.0, 100.0, 100.0],
            filter_loading: false,
        }
    },
    created() {
        this.getTotalStatus();
        this.getUsers();
        this.getCategory();
        this.getTemplates();
        this.getCreator();
    },
    computed: {
        activity_report_list_fields: function () {
            let custom_fields = [
                { key: 'reference_number', label: 'Reference No.' },
                { key: 'description', label: 'Description' },
                { key: 'name', label: 'Template' },
                { key: 'category', label: 'Category' },
                { key: 'creator_name', label: 'Created By' },
                { key: 'for_signing', label: 'For Signing By' },
                { key: 'date', label: 'Date Created' },
                { key: 'status', label: 'Status' }
            ]
            if(this.selected_status === "Completed"){
                custom_fields.splice(6, 0, {key: 'completed_date', label: 'Date Completed'});
                custom_fields.splice(5,1);
            }else if(this.selected_status === "All"){
                custom_fields.splice(6, 0, {key: 'completed_date', label: 'Date Completed'});
            }
            return custom_fields;
        },
    },
    watch: {
        selected_status() {
            if(this.selected_status === "removed"){
                return;
            }
            let startDate, endDate;
            const params = new URLSearchParams();
            this.isFiltered = true;
            this.table_loading = true;
            this.status_filter = true;
            const selectedValue = this.statuses[this.selected_status];

            if (this.selected_creator && this.isAdvanceFilterActive) {
                params.append('creator', this.selected_creator);
            }
            if (this.selected_template_signatory && this.isAdvanceFilterActive) {
                params.append('signatory', this.selected_template_signatory.id);
            }
            if (this.selected_category && this.isAdvanceFilterActive) {
                params.append('category', this.selected_category.id);
            }
            if (this.selected_user_templates && this.isAdvanceFilterActive) {
                params.append('documentTemplate', this.selected_user_templates);
            }
            if ((this.$refs._CDateRange.range && this.$refs._CDateRange.range.start) && (this.$refs._CDateRange.range && this.$refs._CDateRange.range.end) && this.isAdvanceFilterActive) {
                startDate = this.$refs._CDateRange.range.start.getFullYear() + "-" + ("0" + (this.$refs._CDateRange.range.start.getMonth() + 1)).slice(-2) + "-" + ("0" + this.$refs._CDateRange.range.start.getDate()).slice(-2);
                endDate = this.$refs._CDateRange.range.end.getFullYear() + "-" + ("0" + (this.$refs._CDateRange.range.end.getMonth() + 1)).slice(-2) + "-" + ("0" + this.$refs._CDateRange.range.end.getDate()).slice(-2);

                if (startDate) params.append('startDate', startDate);
                if (endDate) params.append('endDate', endDate);
            }

            if (selectedValue) params.append('status', selectedValue);

            const urlAllStatus = `/report/activity-report/activity-report-by-status?${params.toString()}`;
            axios.get(urlAllStatus, { validateStatus: () => true }).then(response => {
                if (response.status == 200) {
                    this.activity_report_list = [];
                    for (let i = 0; i < response.data.length; i++) {
                        let row = {
                            reference_number: response.data[i].reference_number || '',
                            description: response.data[i].description || '',
                            name: response.data[i].name || '',
                            category: response.data[i].category || '',
                            creator_name: response.data[i].creator_name || '',
                            for_signing: response.data[i].for_signing || '',
                            date: response.data[i].date || '',
                            completed_date: response.data[i].completed_date || '',
                            status: response.data[i].status || '',
                        }
                        this.activity_report_list.push(row)

                    }
                    this.table_loading = false;
                    this.status_filter = false;
                } else {
                    console.error('Error fetching data:', response.statusText);
                }
            });
        }
    },
    methods: {
        getTotalStatus: function () {
            axios.get('/report/activity-report/activity-report-list',
                { validateStatus: () => true }).then(response => {
                    if (response.status == 200) {
                        this.status_list = [];
                        this.status_init_value = [];
                        for (let i = 0; i < response.data.data.length; i++) {
                            let row = {
                                draft: response.data.data[i].draft,
                                signing: response.data.data[i].signing,
                                completed: response.data.data[i].completed,
                                cancelled: response.data.data[i].cancelled,
                            }
                            this.status_list.push(row);
                            this.status_init_value.push(row);
                            this.card_loading = false;
                            this.isFiltered = false;
                            this.remove_filter = false;
                        }
                        this.totalActivity = parseInt(this.status_list[0].draft) + parseInt(this.status_list[0].signing)
                            + parseInt(this.status_list[0].completed) + parseInt(this.status_list[0].cancelled) || 0;

                        this.totalStatusPercentage[0] = this.calculateProgressBar(this.status_list[0].draft, this.totalActivity) || 0;
                        this.totalStatusPercentage[1] = this.calculateProgressBar(this.status_list[0].signing, this.totalActivity) || 0;
                        this.totalStatusPercentage[2] = this.calculateProgressBar(this.status_list[0].completed, this.totalActivity) || 0;
                        this.totalStatusPercentage[3] = this.calculateProgressBar(this.status_list[0].cancelled, this.totalActivity) || 0;
                    } else {
                        console.error('Error fetching data:', response.statusText);
                    }
                }
                )
        },
        viewAll: function () {  
            this.isFiltered = true;
            this.selected_status = "All";
        },
        filterActivityReport: function () {
            let startDate, endDate;
            const params = new URLSearchParams();
            this.numberOfFilter = 0;
            this.isAdvanceFilterActive = true;
            this.card_loading = true;
            this.advance_filter = true;
            this.isFiltered = false;
            this.selected_status = "removed";

            if (this.selected_creator) {
                params.append('creator', this.selected_creator);
                this.numberOfFilter++;
            }
            if (this.selected_template_signatory) {
                this.numberOfFilter++;
                params.append('signatory', this.selected_template_signatory.id);
            }
            if (this.selected_category) {
                this.numberOfFilter++;
                params.append('category', this.selected_category.id);
            }
            if (this.selected_user_templates) {
                this.numberOfFilter++;
                params.append('documentTemplate', this.selected_user_templates);
            }

            if ((this.$refs._CDateRange.range && this.$refs._CDateRange.range.start) && (this.$refs._CDateRange.range && this.$refs._CDateRange.range.end)
                || this.selected_creator || this.selected_template_signatory || this.selected_category || this.selected_user_templates) {
                if ((this.$refs._CDateRange.range && this.$refs._CDateRange.range.start) && (this.$refs._CDateRange.range && this.$refs._CDateRange.range.end)) {
                    startDate = this.$refs._CDateRange.range.start.getFullYear() + "-" + ("0" + (this.$refs._CDateRange.range.start.getMonth() + 1)).slice(-2) + "-" + ("0" + this.$refs._CDateRange.range.start.getDate()).slice(-2);
                    endDate = this.$refs._CDateRange.range.end.getFullYear() + "-" + ("0" + (this.$refs._CDateRange.range.end.getMonth() + 1)).slice(-2) + "-" + ("0" + this.$refs._CDateRange.range.end.getDate()).slice(-2);
                    this.numberOfFilter++;
                }

                if (startDate) params.append('startDate', startDate);
                if (endDate) params.append('endDate', endDate);
                const url = `/report/activity-report/filtered-activity-report-list?${params.toString()}`;

                axios.get(url, { validateStatus: () => true }).then(response => {
                    if (response.status == 200) {
                        this.status_list = [];
                        let row = {
                            draft: response.data.data.draft || "0",
                            signing: response.data.data.signing || "0",
                            completed: response.data.data.completed || "0",
                            cancelled: response.data.data.cancelled || "0",
                        };
                        this.totalActivity = parseInt(row.draft) + parseInt(row.signing)
                            + parseInt(row.completed) + parseInt(row.cancelled) || 0;

                        this.status_list.push(row);
                        this.card_loading = false;
                        this.custom_filter = false;
                        this.advance_filter = false;
                        this.progressBar[0] = this.calculateProgressBar(row.draft, this.status_init_value[0].draft);
                        this.progressBar[1] = this.calculateProgressBar(row.signing, this.status_init_value[0].signing);
                        this.progressBar[2] = this.calculateProgressBar(row.completed, this.status_init_value[0].completed);
                        this.progressBar[3] = this.calculateProgressBar(row.cancelled, this.status_init_value[0].cancelled);

                        this.totalStatusPercentage[0] = this.calculateProgressBar(row.draft, this.totalActivity) || 0;
                        this.totalStatusPercentage[1] = this.calculateProgressBar(row.signing, this.totalActivity) || 0;
                        this.totalStatusPercentage[2] = this.calculateProgressBar(row.completed, this.totalActivity) || 0;
                        this.totalStatusPercentage[3] = this.calculateProgressBar(row.cancelled, this.totalActivity) || 0;

                    } else {
                        console.error('Error fetching data:', response.statusText);
                    }
                });
            } else {
                this.export_btn_loading = false;
                this.advance_filter = false;
                this.$Progress.finish()
                this.removeFilter();
                return this.$swal({
                    toast: true,
                    position: "top-right",
                    showConfirmButton: false,
                    timer: 3000,
                    icon: "error",
                    title: "Please Select a filter!",
                    timerProgressBar: true,
                })
            }
        },
        async exportSummary() {
            try {
                this.card_loading = true;
                this.export_btn_loading = true;
                this.table_loading = true;
                this.filter_loading = true;
                let startDate, endDate;
                if ((this.$refs._CDateRange.range && this.$refs._CDateRange.range.start) && (this.$refs._CDateRange.range && this.$refs._CDateRange.range.end) && this.isAdvanceFilterActive) {
                    startDate = this.$refs._CDateRange.range.start.getFullYear() + "-" + ("0" + (this.$refs._CDateRange.range.start.getMonth() + 1)).slice(-2) + "-" + ("0" + this.$refs._CDateRange.range.start.getDate()).slice(-2);
                    endDate = this.$refs._CDateRange.range.end.getFullYear() + "-" + ("0" + (this.$refs._CDateRange.range.end.getMonth() + 1)).slice(-2) + "-" + ("0" + this.$refs._CDateRange.range.end.getDate()).slice(-2);
                }
                const params = new URLSearchParams();

                if (this.selected_creator && this.isAdvanceFilterActive) params.append('creator', this.selected_creator);
                if (this.selected_template_signatory && this.isAdvanceFilterActive) params.append('signatory', this.selected_template_signatory.id);
                if (this.selected_category && this.isAdvanceFilterActive) params.append('category', this.selected_category.id);
                if (this.selected_user_templates && this.isAdvanceFilterActive) params.append('documentTemplate', this.selected_user_templates);
                if (startDate) params.append('startDate', startDate);
                if (endDate) params.append('endDate', endDate);

                const url = `/report/activity-report/export-activity-report-summary?${params.toString()}`;

                const response = await axios.get(url, {
                    responseType: 'arraybuffer',
                });

                const blob = new Blob([response.data]);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'Activity Report (Summary).xlsx';
                link.click();
                this.export_btn_loading = false;
                this.table_loading = false;
                this.$Progress.finish()
                this.removeFilter();
                return this.$swal({
                    toast: true,
                    position: "top-right",
                    showConfirmButton: false,
                    timer: 3000,
                    icon: "success",
                    title: "Download Successfully!",
                    timerProgressBar: true,
                })

            } catch (error) {
                this.export_btn_loading = false;
                this.removeFilter();
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: 'Error exporting Activity Report, Please call system administrator',
                    showConfirmButton: false,
                    timerProgressBar: true,
                });
            }
        },
        async exportByStatus(routingStatus) {
            try {
                this.card_loading = true;
                this.export_btn_loading = true;
                this.remove_filter = true;
                this.filter_loading = true;
                this.table_loading = true;
                let startDate, endDate;

                if ((this.$refs._CDateRange.range && this.$refs._CDateRange.range.start) && (this.$refs._CDateRange.range && this.$refs._CDateRange.range.end) && this.isAdvanceFilterActive) {
                    startDate = this.$refs._CDateRange.range.start.getFullYear() + "-" + ("0" + (this.$refs._CDateRange.range.start.getMonth() + 1)).slice(-2) + "-" + ("0" + this.$refs._CDateRange.range.start.getDate()).slice(-2);
                    endDate = this.$refs._CDateRange.range.end.getFullYear() + "-" + ("0" + (this.$refs._CDateRange.range.end.getMonth() + 1)).slice(-2) + "-" + ("0" + this.$refs._CDateRange.range.end.getDate()).slice(-2);
                }
                const params = new URLSearchParams();

                if (this.selected_creator && this.isAdvanceFilterActive) params.append('creator', this.selected_creator);
                if (this.selected_template_signatory && this.isAdvanceFilterActive) params.append('signatory', this.selected_template_signatory.id);
                if (this.selected_category && this.isAdvanceFilterActive) params.append('category', this.selected_category.id);
                if (this.selected_user_templates && this.isAdvanceFilterActive) params.append('documentTemplate', this.selected_user_templates);
                if (startDate) params.append('startDate', startDate);
                if (endDate) params.append('endDate', endDate);
                if (routingStatus) {
                    const decodedRoutingStatus = decodeURIComponent(routingStatus);
                    params.append('routingStatus', decodedRoutingStatus);
                }
                const url = `/report/activity-report/export-activity-report-by-status-excel?${params.toString()}`;

                const response = await axios.get(url, {
                    responseType: 'arraybuffer',
                });
                let statusFileName;
                let date;
                if (routingStatus === 1) {
                    statusFileName = "Draft"
                } else if (routingStatus === 2) {
                    statusFileName = "Signing"
                } else if (routingStatus === 3) {
                    statusFileName = "Completed"
                } else if (routingStatus === 4) {
                    statusFileName = "Cancelled"
                } else {
                    statusFileName = "All Status"
                }

                if (startDate && endDate) {
                    date = startDate + ' to ' + endDate + ' ';
                } else {
                    date = "";
                }

                const blob = new Blob([response.data]);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'Activity Report ' + date + '(' + statusFileName + ').xlsx';
                link.click();
                this.export_btn_loading = false;
                this.table_loading = false;
                this.$Progress.finish()
                this.removeFilter();
                return this.$swal({
                    toast: true,
                    position: "top-right",
                    showConfirmButton: false,
                    timer: 3000,
                    icon: "success",
                    title: "Download Successfully!",
                    timerProgressBar: true,
                })
            } catch (error) {
                this.export_btn_loading = false;
                this.removeFilter();
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: 'Error exporting Activity Report, Please call system administrator',
                    showConfirmButton: false,
                    timerProgressBar: true,
                });
            }
        },
        getUsers: function () {
            axios.get('/users/active-list', { validateStatus: () => true })
                .then(response => {
                    if (response.status == 200) {
                        this.users = response.data.data
                        this.$Progress.increase(50);
                        this.user_loading = false;
                    }
                })
        },
        getCreator: function () {
            this.$Progress.start()
            axios.get('/drs/document-routing/list-of-creator', { validateStatus: () => true })
                .then(response => {
                    if (response.status == 200) {
                        this.list_of_creator = response.data.data
                        this.$Progress.finish();
                    }
                })
        },
        getCategory: function () {
            axios.get('/drs/document-template-category/active-list', { validateStatus: () => true })
                .then(response => {
                    if (response.status == 200) {
                        this.templates = response.data.data
                        this.$Progress.finish();
                        this.category_loading = false;
                    }
                })
        },
        emitUserModal: function () {
            this.$emit('show_users_modal');
        },
        userSelected: function (object) {
            this.template_signatory.name = object.name
            this.template_signatory.user_id = object.id;
        },
        getTemplates: function () {
            this.$Progress.start()
            axios.get('/drs/document-template/active-list', { validateStatus: () => true })
                .then(response => {
                    if (response.status == 200) {
                        this.list_of_templates = response.data.data
                        this.$Progress.finish();
                        this.templates_loading = false;
                    }
                })
        },
        openAdvanceFilter() {
            if (!this.templates_loading && !this.category_loading && !this.user_loading) {
                this.custom_filter = true;
            }
        },
        removeFilter: function () {
            this.table_loading = true;
            this.remove_filter = true;
            this.card_loading = true;
            this.getTotalStatus();
            this.selected_category = null;
            this.selected_creator = null;
            this.selected_template_signatory = null;
            this.selected_user_templates = null;
            this.progressBar = [100, 100, 100, 100];
            this.$refs._CDateRange.range = null;
            this.activity_report_list = [];
            this.table_loading = false;
            this.numberOfFilter = 0;
            this.isAdvanceFilterActive = false;
            this.filter_loading = false;
            this.isFiltered = false;
            this.selected_status = "removed";
        },
        calculateProgressBar: function (parts, whole) {

            this.percentage = (parts / whole) * 100.0;
            return this.percentage;
        },
        noFilterConfirmation: function (routingStatus) {
            let statusName;

            if (routingStatus === 1) {
                statusName = "marked as <b><i>Draft</i></b>"
            } else if (routingStatus === 2) {
                statusName = "marked as <b><i>Signing</i></b>"
            } else if (routingStatus === 3) {
                statusName = "marked as <b><i>Completed</i></b>"
            } else if (routingStatus === 4) {
                statusName = "marked as <b><i>Cancelled</i></b>"
            } else {
                statusName = ""
            }
            this.$swal({
                icon: "warning",
                html: `<h4>You are about to export data to Excel without applying any filters.</h4>
                All available data ${statusName} will be included in the export.`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Export to Excel",
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    this.$Progress.start()
                    return this.exportByStatus(routingStatus);
                }
            })
        },
    },

}
</script>